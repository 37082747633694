@import url(https://fonts.googleapis.com/css?family=Lato:400,700);
*, *:before, *:after {
  box-sizing: border-box;
}
.container-cm-support {
  margin: 0 auto;
  width: 100%;
  background: #424752;
  /* border-radius: 5px; */
  /* padding: 40px 0; */
  color: white;
  font: 14px/20px "Lato", Arial, sans-serif;
  list-style-type: none;
  height: 105vh;
}

.people-list {
  width: 260px;
  float: left;
  
}
.people-list .search {
  padding: 20px;
}
.people-list input {
  border-radius: 3px;
  border: none;
  padding: 14px;
  color: white;
  background: #6A6C75;
  width: 90%;
  font-size: 14px;
}
.people-list .fa-search {
  position: relative;
  left: -25px;
}
.people-list ul {
  padding: 20px;
  height: 400px;
  list-style-type: none;
}
.people-list ul li {
  padding: 10px 0 20px 0;
  /* border-bottom: 1px solid #fff6f6; */
}
.people-list img {
  float: left;
}
.people-list .about {
  float: left;
  margin-top: 8px;
}
.people-list .about {
  padding-left: 8px;
}
.people-list .status {
  color: #92959E;
}

.chat {
  width: 100%;
  max-width: 70%;
  float: left;
  background: #F2F5F8;
  /* border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; */
  color: #434651;
  height: 105vh;
}
.chat .chat-header {
  padding: 20px;
  border-bottom: 2px solid white;
}
.chat .chat-header img {
  float: left;
}
.chat .chat-header .chat-about {
  float: left;
  padding-left: 10px;
  margin-top: 6px;
}
.chat .chat-header .chat-with {
  font-weight: bold;
  font-size: 16px;
}
.chat .chat-header .chat-num-messages {
  color: #92959E;
}
.chat .chat-header .fa-star {
  float: right;
  color: #D8DADF;
  font-size: 20px;
  margin-top: 12px;
}
.chat .chat-history {
  padding: 30px 30px 20px;
  border-bottom: 2px solid white;
  overflow-y: scroll;
  height: 300px;
  list-style-type: none;
}
.chat .chat-history .ul {
    list-style-type: none;
  }
.chat .chat-history .message-data {
  margin-bottom: 15px;
}
.chat .chat-history .message-data-time {
  color: #a8aab1;
  padding-left: 6px;
}
.chat .chat-history .message {
  color: white;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  margin-bottom: 30px;
  width: 90%;
  position: relative;
}
.chat .chat-history .message:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #86BB71;
  border-width: 10px;
  margin-left: -10px;
}
.chat .chat-history .my-message {
  background: #86BB71;
}
.chat .chat-history .other-message {
  background: #94C2ED;
}
.chat .chat-history .other-message:after {
  border-bottom-color: #94C2ED;
  left: 93%;
}
.chat .chat-message {
  padding: 30px;
}
.chat .chat-message textarea {
  width: 100%;
  border: none;
  padding: 10px 20px;
  font: 14px/22px "Lato", Arial, sans-serif;
  margin-bottom: 10px;
  border-radius: 5px;
  resize: none;
}
.chat .chat-message .fa-file-o, .chat .chat-message .fa-file-image-o {
  font-size: 16px;
  color: gray;
  cursor: pointer;
}
.chat .chat-message button {
  float: right;
  color: #94C2ED;
  font-size: 16px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
  background: #F2F5F8;
}
.chat .chat-message button:hover {
  color: #75b1e8;
}

.online, .offline, .me {
  margin-right: 3px;
  font-size: 10px;
}

.online {
  color: #86BB71;
}

.offline {
  color: #E38968;
}

.me {
  color: #94C2ED;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.float-right {
  float: right;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.inputfile + label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  display: inline-block;
}

.inputfile:focus + label,
.inputfile + label:hover {
  /* background-color: red; */
}
.inputfile + label {
	cursor: pointer; /* "hand" cursor */
}
.inputfile:focus + label {
	outline: -webkit-focus-ring-color auto 5px;
}
#img-uploaded {
  width: 25%;
  max-width: 200px;
}
.testClass{
  max-width: 100px;
  max-height: 100px;
}

.text-center .avatar .img-avatar {
  max-width: 100%;
  max-height: 100%;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.order-head{
  background-color: #66cc66;
  color: #fff;
  padding: 10px;
  font-size: 22px;
}

.green-bo{
border-color: rgb(102 204 102)!important;
}
.card-body{
 padding: 14px!important;
}
.card-body1{
padding: 21px!important;
}
h2.order-head label {
  margin: 0px!important;
}
.ship{
  display: flex;
}
/* button */
.square_btn:hover {
  text-decoration: underline!important;
  color: #fff!important;
}
.square_btn{
  display: inline-block;
  position: relative;
  padding: 0.5em 1.4em;
  text-decoration: none;
  background: #668ad8;/*Button Color*/
  color: #FFF;
  border-bottom: solid 5px #36528c;/*daker than background*/
  border-right: solid 5px #5375bd;/*darker than background*/
  margin-bottom: 10px;
  font-size: 12px;
  width: 314px;
    margin: 5px;
}
.btn1{
  background: #368c6b;/*Button Color*/
  border-bottom: solid 5px #19553d;/*daker than background*/
  border-right: solid 5px #368c6b;/*darker than background*/
  margin-bottom: 10px;
}
.btn2{
  background: #d3602b;/*Button Color*/
  border-bottom: solid 5px #6e3115;/*daker than background*/
  border-right: solid 5px #d3602b;/*darker than background*/
  margin-bottom: 10px;
}

.square_btn:before{    
  content: " ";
  position: absolute;
  bottom: -5px;
  left: -1px;
  width: 0;
  height: 0;
  border-width: 0 6px 6px 0px;
  border-style: solid;
  border-color: transparent;
  border-bottom-color: #FFF;
}

.square_btn:after{   
  content: " ";
  position: absolute;
  top: -1px;
  right: -5px;
  width: 0;
  height: 0;
  border-width: 0px 6px 6px 0px;
  border-style: solid;
  border-color: #FFF;
  border-bottom-color: transparent;
}

.square_btn:active{ /*on click*/
  border:none;
  -ms-transform: translate(6px,6px);
  -webkit-transform: translate(6px,6px);
  transform: translate(6px,6px);/*move LowerRight*/
}

.square_btn:active:after,
.square_btn:active:before {/*on click*/
  content: none;/*Border disappears*/
}
.txt-center{
  text-align: center;
}
.order-head1 {
  background-color: #404fb4;
  color: #fff;
  padding: 10px;
  font-size: 22px;
  border-radius: 0.25rem 0.25rem 0px 0px;
}

.form-flex{
  display: flex;
  padding: 10px;
}
.form-flex input{
  width: 100%;
}
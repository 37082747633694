// Here you can add other styles
// Here you can add other styles
.mr-page-loader {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 9999;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.65);
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
}
.mr-loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #2c7de2;
  border-bottom: 10px solid #2c7de2;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  box-shadow: 0 0 10px rgba(187, 187, 187, 0.7);
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.login_label {
  display: inline-block;
  width: 100%;
  margin-top: 40px;
  position: relative;
  margin-bottom: 0;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #ececec;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
  }
  i {
    font-style: normal;
    z-index: 2;
    position: relative;
    background-color: #fff;
    font-size: 14px;
    line-height: 1;
    display: inline-block;
    padding: 0 10px;
  }
}
.med-dashboard {
  .card-body {
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
    & > div:not(.text-value) {
      font-weight: 500;
    }
  }
}
.card-head-wbtn {
  display: flex;
  align-items: center;
  .float-right {
    margin-left: auto;
  }
  h4 {
    font-size: 18px;
    line-height: 1;
    margin: 0 15px 0 0;
  }
}
.card-header-search {
  margin: 0 15px;
  width: 100%;
  max-width: 300px;
  .ant-input {
    border-radius: 5px;
  }
  .customWrapper {
    padding: 7px 10px;
    font-size: 13px;
    line-height: 1.3;
    border-bottom: 1px solid #e4e4e4;
  }
}
.card-header-option {
  margin: 0 15px;
  display: flex;
  align-items: center;
  label {
    margin: 0;
    line-height: 1;
    font-size: 14px;
    padding-right: 10px;
  }
}
.page-provider-list {
  .table-responsive {
    .badge {
      text-transform: capitalize;
    }
  }
  .avatar img {
    width: 36px;
    height: 36px;
    object-fit: cover;
    object-position: top center;
  }
}
.provider-date-modal .row {
  width: 100%;
  margin: 0;
  & + .row {
    margin-top: 10px;
  }
  label {
    margin: 0;
  }
  .ant-picker {
    width: 100%;
  }
  .col-12.col-md-12 {
    text-align: center;
    .btn-success {
      width: 100%;
      margin-top: 15px;
      max-width: 140px;
    }
  }
}
.btn-tbl-trash {
  border: none;
  padding: 0;
  font-size: 16px;
  width: 32px;
  height: 32px;
}
.ord-summary-card {
  .card-body {
    padding: 0;
  }
  .table-outline {
    border: none;
    margin-bottom: 0;
    th {
      border-top: none;
    }
  }
}
.order-unlisted {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  vertical-align: top;
  li {
    float: left;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 15px;
    line-height: 1.3;
    em {
      padding-right: 10px;
      font-style: normal;
    }
    h4 {
      font-size: 16px;
      margin: 0;
      font-weight: 600;
      line-height: 1;
    }
    strong {
      font-size: 14px;
      text-align: right;
    }
    & + li {
      margin-top: 20px;
    }
    &.alt {
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px dotted #dadada;
      padding-bottom: 7px;
      & + li {
        margin-top: 10px;
      }
    }
    .btn-tbl-edit {
      padding: 0;
      font-size: 15px;
      line-height: 1;
      background-color: #0395da;
      border-radius: 3px;
      width: 24px;
      height: 24px;
      color: #fff;
      margin-left: 10px;
    }
  }
}
.page-order-summary {
  .table-responsive {
    .badge {
      text-transform: capitalize;
    }
    td,
    th {
      padding: 7px 12px;
    }
  }
  .avatar img {
    width: 36px;
    height: 36px;
    object-fit: cover;
    object-position: top center;
  }
}
.ord-summary-card-rt {
  .card-head-wbtn {
    h4 {
      height: 35px;
      display: flex;
      align-items: center;
    }
  }
  .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4 {
      margin: 0;
      padding-right: 10px;
      display: flex;
      flex-direction: column;
      line-height: 1;
      font-size: 20px;
      small {
        font-size: 13px;
        margin-bottom: 2px;
      }
    }
  }
}
.customer-support-block {
  display: flex;
  width: 100%;
  height: calc(100vh - 200px);
}
.customer-support-left {
  float: left;
  width: 320px;
  flex: 0 0 320px;
  height: 100%;
  overflow-y: auto;
  background-color: #012d46;
  .list-group-item {
    border-radius: 0;
    border: none;
    margin: 0;
    background-color: transparent;
    padding: 10px 15px;
    cursor: pointer;
    &.lgp-title {
      background-color: #005484;
      color: #fff;
      font-weight: 700;
    }
    &.list-group-item-action {
      &:hover {
        background-color: #496169;
        .d-flex {
          h5,
          small,
          p {
            color: #fff;
          }
        }
      }
      &.active {
        background-color: #20a8d8;
        .d-flex {
          h5,
          small,
          p {
            color: #fff;
          }
        }
      }
    }
    .avatar {
      border-radius: 50%;
      background-color: #f5f5f5;
    }
    .d-flex {
      img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
      }
      & > span {
        padding-left: 10px;
        flex: 1 1 0px;
      }
      h5 {
        color: #fff;
        font-size: 14px;
        line-height: 1;
        float: left;
        margin: 0;
        font-weight: 600;
      }
      small {
        color: #bfbfbf;
        font-size: 11px;
        padding-left: 5px;
        float: right;
      }
    }
    p {
      margin: 5px 0 0;
      width: 100%;
      float: left;
      font-size: 12px;
      color: #ccc;
      line-height: 1;
    }
  }
}
.customer-support-right {
  float: left;
  flex: 1 1;
  height: 100%;
  background-color: #d2d2d2;
  padding: 15px;
  .list-group {
    display: inline-block;
    vertical-align: top;
    background-color: #005484;
    width: 100%;
    height: 56px;
  }
  .list-group-item {
    border-radius: 0;
    border: none;
    margin: 0;
    background-color: transparent;
    padding: 10px 15px;
    .avatar {
      border-radius: 50%;
      background-color: #f5f5f5;
    }
    .d-flex {
      img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
      }
      & > span {
        padding-left: 10px;
        flex: 1 1 0px;
      }
      h5 {
        color: #fff;
        font-size: 14px;
        line-height: 1;
        float: left;
        margin: 0;
        font-weight: 600;
      }
    }
    p {
      margin: 5px 0 0;
      width: 100%;
      float: left;
      font-size: 12px;
      color: #ccc;
      line-height: 1;
    }
  }
}
.customer-chat-stream {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  height: calc(100% - 131px);
  overflow-y: auto;
  padding: 15px;
  background-color: #fff;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.customer-chat-actions {
  width: 100%;
  display: flex;
  align-items: center;
  height: 75px;
  textarea {
    flex: 1 1 0px;
    height: 75px !important;
    resize: none;
    box-shadow: none !important;
    outline: none !important;
    border-radius: 0 !important;
    border: none !important;
    background-color: #eefaff;
  }
  button {
    border: none !important;
    -webkit-appearance: none;
    border: none;
    height: 75px;
    color: #fff;
    font-size: 20px;
    box-shadow: none;
    outline: none;
    border-radius: 0;
    &:hover {
      opacity: 0.85;
    }
  }
  .cht-send {
    width: 75px;
    background-color: #195383;
  }
  .cht-attach {
    width: 50px;
    background-color: #848484;
  }
}
.cht-bbl {
  float: left;
  width: 100%;
  & + .cht-bbl {
    margin-top: 16px;
  }
  small {
    font-size: 11px;
    color: #888;
    line-height: 1;
    margin-bottom: 8px;
    clear: both;
  }
  span {
    clear: both;
    padding: 12px 15px;
    border-radius: 5px;
    max-width: 80%;
    p {
      margin: 0;
      line-height: 1.5;
      color: #222;
    }
  }
  &.cht-in {
    small {
      float: left;
    }
    span {
      float: left;
      background-color: #ccc;
    }
    p {
      float: left;
    }
  }
  &.cht-out {
    small {
      float: right;
    }
    span {
      float: right;
      background-color: #89c9ff;
    }
    p {
      float: right;
    }
  }
}
.ant-message {
  z-index: 9999;
}
// Mobile
@media only screen and (max-width: 991px) {
  .page-provider-list {
    .card-head-wbtn {
      flex-wrap: wrap;
      padding: 0.75rem;
    }
    .card-header-search {
      max-width: 100%;
      margin: 10px 0;
    }
    .card-header-option {
      margin-left: 0;
    }
    nav.float-left {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .table-responsive {
    margin-bottom: 20px;
    table {
      min-width: 850px;
      .avatar {
        width: 26px;
        height: 26px;
      }
    }
  }
  .pagination {
    flex-wrap: wrap;
  }
  .table th,
  .table td {
    padding: 5px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 767px) {
  .customer-support-block {
    flex-direction: column;
    height: auto;
  }
  .customer-support-left {
    flex: unset;
    width: 100%;
    margin-bottom: 30px;
    height: 300px;
  }
  .customer-support-right {
    flex: unset;
    width: 100%;
    height: 400px;
    margin-bottom: 30px;
    padding: 5px;
  }
  .customer-chat-actions {
    .cht-send {
      width: 55px;
    }
    .cht-attach {
      width: 40px;
    }
  }
}
@media only screen and (max-width: 480px) {
  .med-login-page {
    h1 {
      font-size: 1.8rem;
    }
    .card-body {
      padding: 0;
      .text-right.col-6 {
        padding-left: 0;
      }
    }
  }
  .med-register-page {
    h1 {
      font-size: 1.8rem;
    }
    .mx-4.card {
      margin: 0 !important;
    }
  }
  .page-provider-list {
    .card-header-option {
      width: 100%;
      margin: 0 0 10px;
      justify-content: flex-end;
      .btn {
        margin-left: unset;
      }
    }
  }
  .main .container-fluid {
    padding: 0 15px;
  }
  .card-head-wbtn h4 {
    font-size: 15px;
    margin: 0 10px 0 0;
  }
  .ml-00 {
    margin-left: 0rem !important;
  }

  .col1 {
    flex-basis: 125px !important;
  }
  .col2 {
    flex-basis: 130px !important;
  }
  .d-block {
    display: block !important;
    text-align: center;
  }
}

.rightAllign {
  padding: 1.25rem 0rem;
}

.hoverP {
  //   color: #321fdb;
  p:hover {
    text-decoration: underline;
  }
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  // background-color:lightgray;
  padding: 1rem;
  border: 1px solid #ccc;
  font-size: 14px;
}
.toolbar-class {
  border: 1px solid #ccc;
}
